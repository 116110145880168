// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import Footer from '../../../../components/Common/Footer'
import ConsultationGratuiteForm from '../../../../components/Forms/ConsultationGratuiteForm'

// Images
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'
import OlivierHascoetPicture from '../../../../images/AttorneysSingle/OlivierHascoetSquare.jpg'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'L\'équipe', route: '/avocats' },
    { name: 'Maître Olivier HASCOËT' }
]

const AvocatInfos = 
[
    { level: 'Avocat associé', text: '' },
    { level: 'Domaines de compétence : ', text: 'Droit de la famille, Droit des contrats, Droit locatif et immobilier, Droit du travail, Recouvrement de créances.' },
    { level: 'Téléphone : ', text: '01.69.05.33.99' },
    { level: 'Email : ', text: 'contact@hkhavocats.fr' },
]

const Diplomes = 
[
    'DESS Juriste d’affaires',
    'Certificat d’anglais juridique'
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const OlivierHascoet = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>

            <Breadcrumb
                className="breadcrumbArea"
                title="Maître Olivier HASCOËT"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className='singleTeamArea'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamImgWrap">
                                        <div className="singleTeamImg">
                                            <img src={ OlivierHascoetPicture } alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12">
                                    <div className="singleTeamContent">
                                        <h4>Maître Olivier HASCOËT</h4>
                                        <ul className="teamMemberInfo">
                                            { 
                                                AvocatInfos.map((info, i) => (
                                                    <li key={ i }>
                                                        <span>{ info.level }</span>
                                                        { info.text }
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="singleTeamInfo">
                                        <h3>Expérience professionnelle</h3>
                                        <p>Après avoir obtenu un DESS juriste d’affaires et exerçant depuis plus de 25 ans, il vous assiste, conseille et défend notamment dans les domaines suivants :</p>
                                        <ul>
                                            <li>Droit de la famille (divorce, garde d’enfants, successions, …)</li>
                                            <li>Recouvrement de créances civiles et commerciales</li>
                                            <li>Droit immobilier (location, bail commercial, hypothèques, saisies immobilières, …)</li>
                                        </ul>
                                        
                                        <h5>Diplômes / Formations</h5>
                                        <ul>
                                            { 
                                                Diplomes.map(diplome => (
                                                    <li key={ diplome }>{ diplome }</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConsultationGratuiteForm
                className="contactArea"
            />

            <Footer />
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default OlivierHascoet
// --------------------------------------------------