// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderTop from '../../../components/Common/HeaderTop'
import HeaderBottom from '../../../components/Common/HeaderBottom'
import Breadcrumb from '../../../components/Common/Breadcrumb'
import CabinetInfo from '../../../components/CabinetInfo'
import Portfolio from '../../../components/Portfolio'
import Testmonial from "../../../components/Testmonial";
import Footer from '../../../components/Common/Footer'

// Images
import HeaderPicture from '../../../images/Breadcrumb/AvocatViryChatillon.jpg'
import Divorce from '../../../images/studies/Divorce.jpg'
import TitreDeSejour from '../../../images/studies/TitreDeSejour.jpg'
import GardeEnfant from '../../../images/studies/GardeEnfant.jpg'
import Succession from '../../../images/studies/Succession.jpg'
import PensionAlimentaire from '../../../images/studies/PensionAlimentaire.jpg'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const AboutText = 
[
    { text: 'Si la France compte près de 70.000 avocats dont 30 000 au Barreau de Paris, la cabinet HKH Avocats est le mieux placé pour répondre à vos besoins. Chaque avocat qui le compose a reçu une formation spécifique et se forme chaque année pour être le meilleur dans son domaine.' },
    { text: 'Cabinet à taille humaine, le cabinet HKH Avocats, est toujours disponible pour répondre à vos questions sur une procédure en cours ou à venir.' },
    { text: 'Les rendez-vous se feront toujours en présence d’un associé et d’un collaborateur afin que l’un des deux soit toujours disponible pour vous répondre.' },
    { text: 'Quatre secrétaires expérimentées vous répondent de 9h à 18h et vous répondront même si vos avocats sont en audience ou en déplacement.    ' },
    { text: 'Confiance, maîtrise et efficacité sont nos atouts à votre service pour vous faire gagner vos procès.  ' },
]

const BreadcrumbMenu = 
[
    { name:'Accueil', route:'/' },
    { name:'Le cabinet' }
]

const CacConcret = 
[
    { images: Divorce, title: 'Divorce', subtitle: 'Droit de la famille', route: 'droit-divorce-et-famille' },
    { images: PensionAlimentaire, title: 'Pension alimentaire', subtitle: 'Droit de la famille', route: 'droit-divorce-et-famille' },
    { images: GardeEnfant, title: 'Garde d\'enfant', subtitle: 'Droit de la famille', route: 'droit-divorce-et-famille' },
    { images: Succession, title: 'Infractions', subtitle: 'Droit pénal', route: 'droit-penal' },
    { images: TitreDeSejour, title: 'Titre de séjour', subtitle: 'Droit des étrangers', route: 'droit-des-etrangers' },  
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const CabinetPage = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>

            <Breadcrumb 
                className="breadcrumbArea"
                title="Le cabinet"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />
            
            <CabinetInfo
                className="aboutArea"
                title="Pourquoi nous choisir ?"
                subTitle="Nous sommes professionels"
                orderLast="order-last"
                paragraphs={ AboutText }
            />

            <Portfolio
                className="portfolioArea"
                title="Nous pouvons vous aider"
                subTitle="Cas concret"
                portfolioItem={ CacConcret }
            />

            <Testmonial className="testmonialArea pt100" />

            <Footer/>
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default CabinetPage
// --------------------------------------------------