// -------------------------------------------------- IMPORTS
import React from "react";
import SectionTitle from '../Common/Title'
import { NavLink } from 'react-router-dom'
import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- COMPONENTS
const ServiceArea = ({ className, title, subTitle,services }) => 
{
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {services.map((service, index) => (
                        <div key={index} className="col-lg-6 col-md-6">
                            <NavLink to={ service.route }>
                                <div className="serviceWrap">
                                    <div className="serviceIcon">
                                        <img src={ service.icon } alt="" />
                                    </div>
                                    <div className="serviceContent">
                                        <h3>{ service.title }</h3>
                                        <p>{ service.content }</p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default ServiceArea
// --------------------------------------------------