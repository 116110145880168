// -------------------------------------------------- IMPORTS
import React from 'react'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const listItem = 
[
    'Demander un premier titre de séjour pour vie privée familiale, travail, étudiant, entrepreneur/libéral, en vous assistant dans la constitution du dossier jusqu’au dépôt en préfecture',
    'Solliciter le renouvellement de votre titre de séjour',
    'Demander une carte pluriannuelle ou de résident compte tenu de la durée de votre présence en France',
    'Demander un changement de statut',
    'Faire une demande de naturalisation pour obtenir la nationalité française',
    'Contester le refus de l’administration de vous accorder le titre de séjour. Vos avocats vous accompagnent pour contester les obligations de quitter le territoire français (OQTF) devant le tribunal administratif compétent en sollicitant du juge l’obtention d’un titre de séjour.'
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const DroitEtrangersContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentWrap">
                <p>Vos avocats mettent à votre disposition leurs compétences pour vous conseiller et vous assister dans l’ensemble de vos démarches auprès de la préfecture pour régulariser votre situation administrative, à Paris, Lille et dans l’Essonne.</p>
                <p>Vos avocats vous assisteront également pour contester un refus de l’administration de vous accorder un titre de séjour.</p>
                <p>Les règles du droit des étrangers étant complexes et très encadrées, les démarches en préfecture peuvent s’avérer difficiles.</p>
                <p>Ainsi, vos avocats vous accompagnent pour :</p>
                <div className="avatarWra">
                    <ul>
                        {
                            listItem.map(list => (
                                <li key={list}>{list}</li>
                            ))
                        }
                    </ul>
                </div><br/><br/>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default DroitEtrangersContent
// --------------------------------------------------