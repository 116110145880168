// -------------------------------------------------- IMPORTS
import React, { Fragment, Component, useEffect } from 'react';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom'
// import { ToastContainer } from 'react-toastify';

import Home from '../Pages/Home'
import Cabinet from '../Pages/Cabinet'

import Equipe from '../Pages/Equipe'
import XavierHelain from '../Pages/Equipe/XavierHelain'
import OlivierHascoet from '../Pages/Equipe/OlivierHascoet'
import AurelieNosal from '../Pages/Equipe/AurelieNosal'
import YannHesteau from '../Pages/Equipe/YannHesteau'
import CamilleJami from '../Pages/Equipe/CamilleJami'
import LeaBost from '../Pages/Equipe/LeaBost'

import DomaineDeCompetence from '../Pages/DomaineDeCompetence'
import DroitDivorceEtFamille from '../Pages/DomaineDeCompetence/DroitDivorceEtFamille'
import DroitPenal from '../Pages/DomaineDeCompetence/DroitPenal'
import DroitDesEtrangers from '../Pages/DomaineDeCompetence/DroitDesEtrangers'
import DroitDuTravail from '../Pages/DomaineDeCompetence/DroitDuTravail'
import DroitLocatifImmobilier from '../Pages/DomaineDeCompetence/DroitLocatifImmobilier'
import DroitContratConsommation from '../Pages/DomaineDeCompetence/DroitContratConsommation'
import RecouvrementCreances from '../Pages/DomaineDeCompetence/RecouvrementCreances'

import FAQ from '../Pages/FAQ'
import FAQDroitDesContrats from '../Pages/FAQ/DroitDesContrats'
import FAQDroitDesEtrangers from '../Pages/FAQ/DroitEtrangers'
import FAQDivorceFamille from '../Pages/FAQ/DroitDivorceFamille'
import FAQDroitLocatifImmobilier from '../Pages/FAQ/DroitLocatifImmobilier'
import FAQDroitPenal from '../Pages/FAQ/DroitPenal'
import FAQDroitTravail from '../Pages/FAQ/DroitTravail'
import FAQRecouvrementCreances from '../Pages/FAQ/RecouvrementCreances'

import Contact from '../Pages/Contact'

import './App.css';
import MentionsLegalesPage from '../Pages/MentionsLegales';
// --------------------------------------------------

export function ScrollToTop() 
{
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}


// -------------------------------------------------- COMPONENT
class App extends Component 
{
    state = {
    data: null
    };

    componentDidMount() {
        this.callBackendAPI()
            .then(res => this.setState({ data: res.express }))
            .catch(err => console.log(err));
    }
        

    callBackendAPI = async () => {
        const response = await fetch('/express_backend');
        const body = await response.json();

        if (response.status !== 200) {
            throw Error(body.message) 
        }
        console.log(body);
        return body;
    };

    render() {
        return (
            <Fragment>
                <BrowserRouter>
                    <ScrollToTop />
                    {/* <ToastContainer autoClose={2500} position="top-center" /> */}
                    <Switch>
                        <Route exact path="/" component={ Home } />
                        <Route exact path="/cabinet" component={ Cabinet } />

                        <Route exact path="/avocats" component={ Equipe } />
                        <Route exact path="/avocat-xavier-helain" component={ XavierHelain } />
                        <Route exact path="/avocat-olivier-hascoet" component={ OlivierHascoet } />
                        <Route exact path="/avocat-aurelie-nosal" component={ AurelieNosal } />
                        <Route exact path="/avocat-yann-hesteau" component={ YannHesteau } />
                        <Route exact path="/avocat-camille-jami" component={ CamilleJami } />
                        <Route exact path="/avocat-lea-bost" component={ LeaBost } />

                        <Route exact path="/competences" component={ DomaineDeCompetence } />
                        <Route exact path="/droit-divorce-et-famille" component={ DroitDivorceEtFamille } />
                        <Route exact path="/droit-penal" component={ DroitPenal } />
                        <Route exact path="/droit-des-etrangers" component={ DroitDesEtrangers } />
                        <Route exact path="/droit-du-travail" component={ DroitDuTravail } />
                        <Route exact path="/droit-locatif-et-immobilier" component={ DroitLocatifImmobilier } />
                        <Route exact path="/droit-contrats-et-consommation" component={ DroitContratConsommation } />
                        <Route exact path="/recouvrement-de-creances" component={ RecouvrementCreances } />

                        <Route exact path="/faq" component={ FAQ } />
                        <Route exact path="/faq-droit-contrats" component={ FAQDroitDesContrats } />
                        <Route exact path="/faq-droit-etrangers" component={ FAQDroitDesEtrangers } />
                        <Route exact path="/faq-droit-famille" component={ FAQDivorceFamille } />
                        <Route exact path="/faq-locatif-immobilier" component={ FAQDroitLocatifImmobilier } />
                        <Route exact path="/faq-droit-penal" component={ FAQDroitPenal } />
                        <Route exact path="/faq-droit-travail" component={ FAQDroitTravail } />
                        <Route exact path="/faq-recouvrement" component={ FAQRecouvrementCreances } />

                        <Route exact path="/mentions-legales" component={ MentionsLegalesPage } />
                        <Route exact path="/contact" component={ Contact } />
                    </Switch>
                </BrowserRouter>
            </Fragment>
        );
    }
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default App;
// --------------------------------------------------