// -------------------------------------------------- IMPORTS
import React from 'react'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const listItem = 
[
    'Contrat de travail ;',
    'Clauses de non concurrence ;',
    'Clauses du contrat ;',
    'Période d’essai ;',
    'Licenciement ;',
    'Démission ;',
    'Rupture conventionnelle / transaction ;',
    'Procédure disciplinaire ;',
    'Harcèlement ;',
    'Heures supplémentaires ;',
    'Arrêt maladie ;',
    'Mobilité ;'
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const DroitTravailContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentWrap">
                <p>Vos avocats conseillent et assistent les entreprises et les salariés dans leur gestion précontentieuse et contentieuse devant les conseils de prud’hommes à Paris, Lille et dans l’Essonne.</p>

                <p>Ils interviennent dans les domaines suivants :</p>
                <div className="avatarWra">
                    <ul>
                        {
                            listItem.map(list => (
                                <li key={list}>{list}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default DroitTravailContent
// --------------------------------------------------