// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderTop from '../../../components/Common/HeaderTop'
import HeaderBottom from '../../../components/Common/HeaderBottom'
import HeaderPicture from '../../../images/Breadcrumb/AvocatViryChatillon.jpg'
import Breadcrumb from '../../../components/Common/Breadcrumb'
import EquipeAvocats from '../../../components/EquipeAvocats'
import Footer from '../../../components/Common/Footer'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'L\'équipe' }
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const EquipePage = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>

            <Breadcrumb
                className="breadcrumbArea"
                title="L'équipe"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <EquipeAvocats
                title="Avocats Associés / Collaborateurs"
                subTitle="RENCONTREZ NOS EXPERTS"
                className="teamArea"
                noGutters={ true }
            />

            <Footer />
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default EquipePage
// --------------------------------------------------