// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../components/Common/HeaderBottom'
import HeaderTop from '../../../components/Common/HeaderTop'
import Breadcrumb from '../../../components/Common/Breadcrumb'
import Footer from '../../../components/Common/Footer'
import ServiceArea from '../../../components/ServiceArea'

import HeaderPicture from '../../../images/Breadcrumb/AvocatViryChatillon.jpg'
import DroitPenal from '../../../images/Skills/DroitPenal.svg'
import DroitDuTravail from '../../../images/Skills/DroitDuTravail.svg'
import DroitDivorceFamille from '../../../images/Skills/DroitDivorceFamille.svg'
import DroitDesEtrangers from '../../../images/Skills/DroitDesEtrangers.svg'
import DroitConsommation from '../../../images/Skills/DroitConsommation.svg'
import DroitLocatifImmobilier from '../../../images/Skills/DroitLocatifImmobilier.svg'
import RecouvrementCreances from '../../../images/Skills/RecouvrementCreances.svg'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'Domaines de compétence' }
]

const Domains =
[
    {
        icon: DroitDivorceFamille,
        title: 'Droit du divorce et de la famille',
        content: 'Une simple question ou un problème en matière familiale, pour un divorce ou une succession, vos avocats vous offrent une première consultation gratuite.',
        route: 'droit-divorce-et-famille'
    },
    {
        icon: DroitPenal,
        title: 'Droit pénal',
        content: 'Auteur ou Victime d’infractions, en garde à vue ou en prison, vos avocats vous offrent une première consultation gratuite.',
        route: 'droit-penal'
    },
    {
        icon: DroitDesEtrangers,
        title: 'Droit des étrangers',
        content: 'Obligation de quitter le territoire (OQTF) ou régularisation administrative d’un étranger, vos avocats vous offrent une première consultation gratuite.',
        route: 'droit-des-etrangers'
    },
    {
        icon: DroitDuTravail,
        title: 'Droit du travail',
        content: 'Une simple question ou un problème en droit du travail avec votre employeur ou votre salarié, vos avocats vous offrent une première consultation gratuite.',
        route: 'droit-du-travail'
    },
    {
        icon: DroitLocatifImmobilier,
        title: 'Droit locatif et immobilier',
        content: 'Une simple question ou un problème de bail ou de loyer avec votre locataire ou votre propriétaire, vos avocats vous offre une première consultation gratuite.',
        route: 'droit-locatif-et-immobilier'
    },
    {
        icon: DroitConsommation,
        title: 'Droit des contrats et consommation',
        content: 'Pour interpréter, rédiger ou annuler un contrat, vos avocats vous offrent une première consultation gratuite.',
        route: 'droit-contrats-et-consommation'
    },
    {
        icon: RecouvrementCreances,
        title: 'Recouvrement de créances',
        content: 'Que vous soyez débiteur ou créancier, vos avocats vous offrent une première consultation gratuite.',
        route: 'recouvrement-de-creances'
    },
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const DomaineDeCompetencePage = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb
                className="breadcrumbArea"
                title="Domaines de compétence"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />
            
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleThree"
                title="Comment pouvons-nous vous aider?"
                subTitle="DOMAINES DE COMPÉTENCE"
                services={ Domains }
            />

            <Footer /> 
        </Fragment>
    )
}
// -------------------------------------------------- 


// -------------------------------------------------- EXPORTS
export default DomaineDeCompetencePage
// --------------------------------------------------