// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../components/Common/HeaderBottom'
import HeaderTop from '../../../components/Common/HeaderTop'
import Breadcrumb from '../../../components/Common/Breadcrumb'
import FAQTable from "../../../components/FAQTable";
import Footer from '../../../components/Common/Footer'
import HeaderPicture from '../../../images/Breadcrumb/AvocatViryChatillon.jpg'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name:'Accueil',route:'/' },
    { name:'FAQ' }
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const FAQPage = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb 
                className="breadcrumbArea"
                title="Foire aux questions"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <FAQTable 
                className="pricingTableArea"
                title="Pricing Table"
                subTitle="Our Pricing Plan"
            />

            <Footer/>
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default FAQPage
// --------------------------------------------------