// -------------------------------------------------- IMPORTS
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import HKHLogo from '../../../images/Logo/LogoHKHAvocats.png'
import './style.scss'
// --------------------------------------------------

// -------------------------------------------------- COMPONENT
const HeaderBottom = props => 
{
    // ---------------------------------------------- STATES
    const [responsive, setResponsive] = useState(false)
    // ----------------------------------------------


    // ---------------------------------------------- HANDLERS
    const responsiveHandler = () => 
    {
        setResponsive(!responsive)
    }
    // ---------------------------------------------- 

    // ---------------------------------------------- JSX
    return (
        <div className={ props.className }>
            <div className="container">
                <div className="headerBottomMainWrapper">
                    <div className="row">

                        {/* Logo */}
                        <div className="col-lg-2 col-md-2 col-sm-6 col-8">
                            <div className="Logo">
                                <NavLink to="/">
                                    <img src={ HKHLogo } alt="" />
                                </NavLink>
                            </div>
                        </div>

                        <div className={ responsive ? "col-lg-8 responsiveWrapper active" : "col-md-10 col-lg-10 responsiveWrapper"}>
                            <ul className="mainMenuWrap">
                                <li><NavLink exact to='/'>Accueil</NavLink></li>
                                <li><NavLink exact to='/cabinet'>Le Cabinet</NavLink></li>
                                <li><NavLink exact to='/avocats'>L'Équipe</NavLink>
                                    <ul className="subMenu">
                                        <li><NavLink exact to='/avocat-olivier-hascoet'>Maître Olivier HASCOËT</NavLink></li>
                                        <li><NavLink exact to='/avocat-xavier-helain'>Maître Xavier HELAIN</NavLink></li>
                                        <li><NavLink exact to='/avocat-aurelie-nosal'>Maître Aurélie NOSAL</NavLink></li>
                                        <li><NavLink exact to='/avocat-yann-hesteau'>Maître Yann HESTEAU</NavLink></li>
                                        <li><NavLink exact to='/avocat-camille-jami'>Maître Camille JAMI</NavLink></li>
                                        <li><NavLink exact to='/avocat-lea-bost'>Maître Léa BOST</NavLink></li>
                                    </ul>
                                </li>
                                <li><NavLink exact to='/competences'>Domaines de compétence</NavLink>
                                    <ul className="subMenu">
                                        <li><NavLink exact to='/droit-divorce-et-famille'>Droit du divorce et de la famille</NavLink></li>
                                        <li><NavLink exact to='/droit-penal'>Droit pénal</NavLink></li>
                                        <li><NavLink exact to='/droit-des-etrangers'>Droit des étrangers</NavLink></li>
                                        <li><NavLink exact to='/droit-du-travail'>Droit du travail</NavLink></li>
                                        <li><NavLink exact to='/droit-locatif-et-immobilier'>Droit locatif et immobilier</NavLink></li>
                                        <li><NavLink exact to='/droit-contrats-et-consommation'>Droit des contrats et consommation</NavLink></li>
                                        <li><NavLink exact to='/recouvrement-de-creances'>Recouvrement de créances</NavLink></li>

                                    </ul>
                                </li>
                                <li><NavLink exact to='/faq'>FAQ</NavLink></li>
                                <li><NavLink exact to='/contact'>Contact</NavLink></li>
                            </ul>
                        </div>
                        
                        <div className="col-lg-1 col-md-2 col-sm-6 col-4">
                            <div className="searchMenuWrapper">
                                <div onClick={responsiveHandler} className="responsiveTrigger">
                                    <span className="first"></span>
                                    <span className="second"></span>
                                    <span className="third"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    // ----------------------------------------------
}

// -------------------------------------------------- EXPORTS
export default HeaderBottom
// --------------------------------------------------