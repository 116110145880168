// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import FooterArea from '../../../../components/Common/Footer'
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'FAQ', route: '/faq' },
    { name: 'Questions relatives au droit du travail' }
]

const listItem = 
[
    'Un certificat de travail mentionnant les dates d’entrée et de sortie du salarié dans l’entreprise,',
    'Un reçu pour solde de tout compte précisant l’ensemble des sommes versées au salarié à l’issue de son contrat de travail, accompagné de la dernière fiche de paie,',
    'Une attestation Pôle emploi.',
]
// -------------------------------------------------- 


// -------------------------------------------------- COMPONENTS
const FAQDroitTravail = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb 
                className="breadcrumbArea"
                title="Questions relatives au droit du travail"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="singleContentWrap">

                            <h1>Quelle est la procédure devant le conseil de prud’hommes ? </h1><br/>
                            <p>Le conseil de prud’hommes est saisi sur requête, qui détaille les arguments de fait et de droit ainsi que le montant des demandes.<br/>
                            Les parties sont convoquées devant le bureau de conciliation et d’orientation. En cas d’accord à cette audience, le litige prend fin.<br/>
                            En l’absence d’accord, le dossier est renvoyé devant le bureau de jugement devant lequel l’affaire sera plaidée après échanges d’écritures entre les parties.<br/>
                            En cas de partage des voix devant le bureau de jugement, l’affaire est renvoyée à une audience de départage, lors de laquelle un juge accompagne les 2 conseillers « salarié » et les 2 conseillés « employeur ».</p><br/><br/>
                            
                            <h1>Quand s’applique une clause de non concurrence ?</h1><br/>
                            <p>La clause de non concurrence est une disposition écrite figurant dans le contrat de travail et dont l'objet est d'interdire à un ancien salarié l'exercice d'une activité professionnelle concurrente qui porterait atteinte aux intérêts de son ancien employeur pendant une certaine durée après son départ de l'entreprise et dans un certain espace géographique.<br/>
                            Il existe néanmoins un contrôle par les juges du régime juridique de ces clauses qui doivent répondre à plusieurs critères.<br/>
                            Attention cependant car, indépendamment de toute clause en ce sens, le salarié, pendant toute la durée d’exécution de son contrat de travail, ne doit pas exercer d’activité concurrente à celle de son employeur. </p><br/><br/>
                        
                            <h1>Quelle est la procédure de rupture conventionnelle ?</h1><br/>
                            <p>La proposition de la rupture conventionnelle peut être à l’initiative du salarié ou de l’employeur.<br/>
                            L’employeur adresse alors une lettre d’invitation à un entretien de négociation.<br/>
                            Suite à un ou plusieurs entretiens, la rupture conventionnelle peut être signée.<br/>
                            A compter de la date de signature de la rupture conventionnelle, l’employeur et le salarié ont un délai de réflexion de 15 jours calendaires.<br/>
                            En l’absence de rétractation, l’employeur fait une demande d’homologation à la DIRECCTE.<br/>
                            Une fois l’homologation actée, le contrat est rompu à la date prévue par la convention. </p><br/><br/>

                            <h1>Quels sont les documents de fin de contrat que doit me remettre mon employeur ? </h1><br/>
                            <p>Lors de la rupture ou la fin d’un contrat de travail, un employeur est tenu de remettre au salarié :</p>
                            <ul>
                                {
                                    listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))
                                }
                            </ul><br/><br/>
                        </div>
                    </div>
                </div>
            </div>


            <FooterArea />
        </Fragment>
    )
}
// -------------------------------------------------- 


// -------------------------------------------------- EXPORTS
export default FAQDroitTravail
// --------------------------------------------------