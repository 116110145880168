// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import FooterArea from '../../../../components/Common/Footer'

import './style.scss'

// Images
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'FAQ', route: '/faq' },
    { name: 'Questions relatives au droit des étrangers' }
]
// -------------------------------------------------- 


// -------------------------------------------------- COMPONENTS
const FAQDroitDesEtrangers = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb 
                className="breadcrumbArea"
                title="Questions relatives au droit des étrangers"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="singleContentWrap">

                            <h1>Qu’est-ce qu’un titre de séjour ?</h1><br/>
                            <p>C’est le droit accordé par l’administration française de rester temporairement sur le territoire français et pour prouver ce droit, l’administration vous remet une carte de séjour qui peut être fondée sur différents motifs liés à votre situation personnelle (étudiant, familial, travail…) </p><br/><br/>
                            
                            <h1>Comment obtenir une carte de séjour ?</h1><br/>
                            <p>Il vous faut remplir certaines conditions selon votre situation personnelle. <br/>
                            Par exemple, vous pouvez obtenir un titre de séjour « vie privée et familiale » si vous justifier d’une présence minimum de 5 ans en France et de liens familiaux suffisants.<br/>
                            Si vous remplissez les conditions, il vous faudra déposer un dossier à la préfecture de votre lieu de résidence et fournir un certain nombre de document justificatif.</p><br/><br/>

                            <h1>Qu’est-ce qu’une obligation de quitter le territoire français (OQTF) ?</h1><br/>
                            <p>C’est la décision rendu par la préfecture pour vous expulser du territoire français.</p><br/><br/>
                            
                            <h1>Peut-on contester une OQTF et pendant combien de temps ? </h1><br/>
                            <p>Il est parfaitement possible de contester une OQTF en saisissant le tribunal administratif compétent à compter de la réception de la décision.<br/>
                                Attention, il faut agir vite car les délais pour contester sont très courts, cela peut aller de 48 heures à 30 jours selon la décision. <br/>
                                Si vous êtes dans cette situation, il faut contacter un avocat dès réception de la décision.</p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterArea />
        </Fragment>
    )
}
// -------------------------------------------------- 


// -------------------------------------------------- EXPORTS
export default FAQDroitDesEtrangers
// --------------------------------------------------