// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import FooterArea from '../../../../components/Common/Footer'

import './style.scss'

// Images
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'FAQ', route: '/faq' },
    { name: 'Questions relatives au droit pénal' }
]
// -------------------------------------------------- 


// -------------------------------------------------- COMPONENTS
const FAQDroitPenal = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb 
                className="breadcrumbArea"
                title="Questions relatives au droit pénal"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="singleContentWrap">

                        <h1>Quand peut-on être placé en garde à vue ?</h1><br/>
                        <p>Un placement en garde à vue peut être ordonné dès lors qu’il existe des indices graves et concordants laissant supposer la participation de l’intéressé à une infraction pour laquelle une peine de prison est encourue.</p><br/><br/>
                        
                        <h1>Quelle est la durée d’une garde à vue ?</h1><br/>
                        <p>Dans la plupart des cas, la garde à vue est prévue pour une durée maximale de 24 heures reconductibles une fois.<br/>
                        La loi prévoit cependant une prolongation supplémentaire de 48 heures pour certaines infractions énumérées dans le code de procédure pénale.</p><br/><br/>

                        <h1>Ai-je droit à un avocat pendant ma garde à vue ?</h1><br/>
                        <p>Pendant la garde à vue, vous pouvez demander à être assisté d’un avocat qui s’entretiendra confidentiellement avec nous pendant une durée de 30 min par tranche de 24 heures.<br/>
                        Il suffit d’en faire la demande après de l’officier de police judiciaire.</p><br/><br/>
                        
                        <h1>Quel est le rôle de mon avocat pendant ma garde à vue ?</h1><br/>
                        <p>Votre avocat entretient avec vous dans des conditions respectant la confidentialité des échanges et vous assiste durant vos auditions et confrontations.</p><br/><br/>

                        <h1>Que se passe-t-il à l’issue de ma garde à vue ?</h1><br/>
                        <p>A l’issue de la garde à vue, le procureur décide de la suite à donner en fonction des éléments recueillis au cours de l’enquête et de la garde à vue.</p><br/><br/>

                        <h1>Convoqué pour une audition libre, ai-je le droit à un avocat ?</h1><br/>
                        <p>Lorsque les faits qui vous sont reprochés sont puni d’une peine d’emprisonnement, vous avez la possibilité de demander à être assisté d’un avocat.<br/>
                        Dès réception de la convocation, vous devez contacter votre avocat pour vous entretenir avec lui.</p><br/><br/>

                        <h1>Je suis convoqué en tant que victime ou mise en cause devant le juge d’instruction : </h1><br/>
                        <p>Dès réception de la convocation, vous devez prendre attache avec votre avocat pour préparer votre audition devant le juge d’instruction. <br/>
                        Votre avocat aura la possibilité de consulter le dossier jusqu’avant l’audition.</p><br/><br/>

                        <h1>Je suis convoqué devant le juge de la liberté et de la détention, que faire ?</h1><br/>
                        <p>Dès réception de la convocation, vous devez prendre attache avec votre avocat pour que puisse être examiné votre situation.<br/>
                        Il préparera avec vous les justificatifs nécessaires pour éviter certaines mesures qui pourraient être prises comme un placement en détention provisoire ou un contrôle judiciaire.</p><br/><br/>



                        <h1>J’ai déposé plainte mais je ne sais pas où en est mon affaire ?</h1><br/>
                        <p>Le dépôt d’une plainte entraine l’ouverture d’une enquête lorsque les faits sont constitués.<br/>
                        Votre avocat se charge du suivi de la plainte auprès des différents interlocuteurs.</p><br/><br/>

                        <h1>Comment évaluer mon préjudice devant le tribunal ?</h1><br/>
                        <p>Votre avocat vous aidera à établir l’ensemble des préjudices pour lesquels vous pouvez prétendre à une indemnisation et, mettant son expérience à votre profit, chiffrera votre demande.<br/>
                        Dans les situations les plus complexes, votre avocat peut solliciter la désignation d’un expert afin de déterminer au plus juste le préjudice subi.</p><br/><br/>

                        <h1>Je suis victime d’une infraction et souhaite déposer plainte</h1><br/>
                        <p>Notre cabinet vous assiste dans vos démarches et durant toute la procédure d’enquête jusqu’au jugement, y compris pendant l’instruction si celle-ci a été ordonnée.<br/>
Votre avocat est à vos côtés durant les auditions ou confrontations auxquels vous pourriez être convoqué.</p><br/><br/>
                        </div>
                    </div>
                </div>
            </div>

            <FooterArea />
        </Fragment>
    )
}
// -------------------------------------------------- 


// -------------------------------------------------- EXPORTS
export default FAQDroitPenal
// --------------------------------------------------