// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../components/Common/HeaderBottom'
import HeaderTop from '../../../components/Common/HeaderTop'
import HeroSlider from '../../../components/HeroSlider'
import SkillsDomains from '../../../components/Home/DomainesDeComp'
import HKHPropos from '../../../components/Home/HKHPropos'
import ContactNumber from '../../../components/Home/ContactNumber'
import EquipeAvocats from '../../../components/EquipeAvocats'
import FooterArea from '../../../components/Common/Footer'

// Images
// import about from '../../../images/about/2.jpg'

import DroitPenal from '../../../images/Skills/DroitPenal.svg'
import DroitDuTravail from '../../../images/Skills/DroitDuTravail.svg'
import DroitDivorceFamille from '../../../images/Skills/DroitDivorceFamille.svg'
import DroitDesEtrangers from '../../../images/Skills/DroitDesEtrangers.svg'
import DroitConsommation from '../../../images/Skills/DroitConsommation.svg'
import DroitLocatifImmobilier from '../../../images/Skills/DroitLocatifImmobilier.svg'
import RecouvrementCreances from '../../../images/Skills/RecouvrementCreances.svg'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const heroSliders = 
[
    {
        images: 'slideWrapperOne',
        title: '20 personnes compétentes',
        subTitle: '40 ans d’expérience.',
        text: 'A VOTRE SERVICE',
        button: 'Contactez nous'
    },
    {
        images: 'slideWrapperTwo',
        title: 'Vos avocats vous assistent en Région Parisienne',
        subTitle: 'et les Hauts de France.',
        text: 'A VOTRE SERVICE',
        button: 'Contactez nous'
    }
]

const Domains = 
[
    {
        icon: DroitDivorceFamille,
        title: 'Droit du divorce et de la famille',
        content: 'Une simple question ou un problème en matière familiale, pour un divorce ou une succession, vos avocats vous offrent une première consultation gratuite.'
    },
    {
        icon: DroitPenal,
        title: 'Droit pénal',
        content: 'Auteur ou Victime d’infractions, en garde à vue ou en prison, vos avocats vous offrent une première consultation gratuite.'
    },
    {
        icon: DroitDesEtrangers,
        title: 'Droit des étrangers',
        content: 'Obligation de quitter le territoire (OQTF) ou régularisation administrative d’un étranger, vos avocats vous offrent une première consultation gratuite.'
    },
    {
        icon: DroitDuTravail,
        title: 'Droit du travail',
        content: 'Une simple question ou un problème en droit du travail avec votre employeur ou votre salarié, vos avocats vous offrent une première consultation gratuite.'
    },
    {
        icon: DroitLocatifImmobilier,
        title: 'Droit locatif et immobilier',
        content: 'Une simple question ou un problème de bail ou de loyer avec votre locataire ou votre propriétaire, vos avocats vous offre une première consultation gratuite.'
    },
    {
        icon: DroitConsommation,
        title: 'Droit des contrats et consommation',
        content: 'Pour interpréter, rédiger ou annuler un contrat, vos avocats vous offrent une première consultation gratuite.'
    },
    {
        icon: RecouvrementCreances,
        title: 'Recouvrement de créances',
        content: 'Que vous soyez débiteur ou créancier, vos avocats vous offrent une première consultation gratuite.'
    },
]

const AboutText = 
[
    { text: 'Depuis plus de 40 ans, le cabinet HKH Avocats vous conseille et vous assiste dans tous les domaines du droit, notamment en matière d’affaires familiales et de divorce, de droit pénal, de droit des contrats, de droit du travail ou encore en droit des étrangers, droit de la consommation et recouvrement de créances.' },
    { text: 'Le cabinet HKH Avocats est une société inter-barreaux qui dispose de deux cabinets dans l’ESSONNE, dont l’un est directement implanté dans les locaux d’un office notarial pour faciliter les divorces par consentement mutuel, et d’un cabinet à Lille. Ainsi vos avocats peuvent plaider dans toute la région parisienne et dans les Hauts de France, à la Cour d’appel de PARIS comme à la Cour d’appel de DOUAI.' },
    { text: 'Le cabinet HKH Avocats dispose par ailleurs d’un solide réseau d’avocats partout en France pour vous assister devant toutes les juridictions françaises.' },
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const Home = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>

            <HeroSlider 
                sliders={heroSliders}
                className="heroSliderArea" 
            />

            <SkillsDomains
                className="ourSkillsDomain"
                Title="Comment pouvons-nous vous aider?"
                SubTitle="Domaines De Compétence"
                SkillsDomains={ Domains }
            />

            <HKHPropos
                className="proposArea"
                title="A propos"
                images={ DroitPenal }
                paragraphs={ AboutText }
            />

            <ContactNumber className="serviceArea" />

            <EquipeAvocats
                title="Avocats Associés / Collaborateurs"
                subTitle="Rencontrez nos experts"
                className="teamArea"
                slider={ true }
            />

            <FooterArea/>
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default Home
// --------------------------------------------------