// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import Footer from '../../../../components/Common/Footer'
import DroitLocatifImmobilierContent from '../../../../components/DomaineDeCompetence/DroitLocatifImmobilierContent'
import EquipeAvocatsImmobilier from '../../../../components/EquipeAvocatsImmobilier'

import './style.scss'

// Images
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = [
    { name: 'Accueil', route: '/' },
    { name: 'Domaines de compétence', route: '/competences' },
    { name: 'Droit locatif et immobilier' }
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const DroitLocatifImmobilierPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb
                className="breadcrumbArea"
                title="Droit locatif et immobilier"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />
            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <DroitLocatifImmobilierContent
                                className="singleContentArea"
                                avatar={true}
                            />
                        </div>

                        <EquipeAvocatsImmobilier
                            title="Nos Avocats Vous Accompagnent"
                            subTitle="RENCONTREZ NOS EXPERTS"
                            className="teamArea"
                            noGutters={ true }
                        />

                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default DroitLocatifImmobilierPage
// -------------------------------------------------- 