// -------------------------------------------------- IMPORTS
import React from 'react'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS


const listVictimes = 
[
    'Pour toutes les créances, quel que soit leur montant',
    'Devant le tribunal de proximité',
    'Devant le tribunal judiciaire',
    'Devant le tribunal de commerce'
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const RecouvrementCreancesContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentWrap">
                <p>Forts d’un savoir-faire acquis depuis plus de 40 ans dans le domaine du recouvrement de créances, vos avocats mettront leur expertise à votre service pour garantir vos créances et maximiser vos chances de recouvrement, tant amiable que judiciaire.</p>

                <p>Votre entreprise doit faire face à des factures impayées ?</p>

                <p>Malgré une décision de justice, la pension alimentaire ou la prestation compensatoire qui vous a été accordée n’est pas payée ?</p>

                <p>Vous avez consenti un prêt, à titre professionnel ou à un proche, mais celui-ci n’est pas remboursé ?</p>

                <p>Vous rencontrez une situation quelconque où l’on vous doit de l’argent ?</p>

                <p>Vos avocats sont de véritables experts dans le domaine du recouvrement de créances, compétents sur Paris, Lille et l’Essonne</p>

                <p>Vos avocats sont là pour vous aider, vous accompagner, vous représenter, entamer une procédure amiable et, lorsque celle-ci n’est pas suffisante, diligenter toute action devant les juridictions ou instances compétentes, et ce toujours dans l’objectif absolu de préserver vos droits.</p>

                <p>Par ailleurs, vos avocats pourront solliciter des mesures conservatoires (hypothèque judiciaire par exemple), et, devant l’inertie de votre débiteur à s’exécuter malgré un jugement ou une ordonnance le condamnant, engager toute procédure d’exécution forcée sur la base du titre exécutoire obtenu (saisie des rémunérations, saisie-vente, saisie sur compte bancaire, etc.).</p>

                <p>Nous intervenons :</p>

               
                <div className="avatarWra">
                    <ul>
                        {
                            listVictimes.map(list => (
                                <li key={list}>{list}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default RecouvrementCreancesContent
// --------------------------------------------------