// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'

import Footer from '../../../../components/Common/Footer'
import EquipeAvocatEtrangers from '../../../../components/EquipeAvocatEtrangers'

// Images
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'

import './style.scss'
import DroitEtrangersContent from '../../../../components/DomaineDeCompetence/DroitEtrangersContent'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'Domaines de compétence', route: '/competences' },
    { name: 'Droit des étrangers' }
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const DroitDesEtrangersPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb
                className="breadcrumbArea"
                title="Droit des étrangers"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />
            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <DroitEtrangersContent
                                className="singleContentArea"
                                // image={Famille}
                                avatar={true}
                            />
                        </div>

                        <EquipeAvocatEtrangers
                            title="Nos Avocats Vous Accompagnent"
                            subTitle="RENCONTREZ NOS EXPERTS"
                            className="teamArea"
                            noGutters={ true }
                        />

                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default DroitDesEtrangersPage
// -------------------------------------------------- 