// -------------------------------------------------- IMPORTS
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss'

// Images
import XavierHelain from '../../images/testimonial/XavierHelainBlack.png'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const sliders = 
[
    {
        text: 'REMARQUABLE ! Un dossier plus que difficile parfaitement bien géré avec compétences! Un grand Merci à Maître HASCOUET ainsi qu\'à Maître PERRAULT! 2 professionnels en qui j\'ai toute confiance.',
        title: 'Francine Boulay',
    },
    {
        text: 'Cabinet très professionnel, à l\'écoute des personnes et avec du résultat! On se sent épaulé dès le premier rendez-vous. Je recommande totalement..',
        title: 'Zoé Bourdy',
    },
    {
        text: 'Très bon accueil, une grande écoute et des conseils avisés. Je recommande ce cabinet d\'avocats',
        title: 'Severine Deregnaucourt',
    },
    {
        text: 'Beaucoup de professionalisme avec des personnes compétentes et à l écoute. Je vous les recommande sans hesitation',
        title: 'Maxime Catorc',
    }
]
const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};
// -------------------------------------------------- 


// -------------------------------------------------- COMPONENT
const Testmonial = ({ className }) => 
{
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="testimonialImages">
                            <img src={XavierHelain} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 offset-lg-1 col-md-6">
                        <div className="testimonialContent">
                            <span>Nos retours</span>
                            <h2>Témoignages client</h2>
                            <Slider className="testmonialSlider" {...settings}>
                                {
                                    sliders.map((slider, i) => (
                                        <div key={i} className="slideItem">
                                            <p>{slider.text}</p>
                                            <div className="thumbWrap">
                                                <div className="imgContent">
                                                    <h4>{slider.title}</h4>
                                                    <span>{slider.subTitle}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default Testmonial
// --------------------------------------------------