// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import FooterArea from '../../../../components/Common/Footer'
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const listItem = 
[
    'Par consentement mutuel : procédure hors tribunal pour laquelle les avocats rédigent une convention de divorce qui est ensuite enregistrée par un notaire. Cela nécessite d’être d’accord sur le principe du divorce, mais également sur ses conséquences (garde des enfants, pension alimentaire, partage des biens)',
    'Par saisine du Juge aux affaires familiales : l’avocat rédige une assignation en divorce, qui est délivrée par huissier de justice à l’autre conjoint',
]
// --------------------------------------------------

// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'FAQ', route: '/faq' },
    { name: 'Questions relatives au droit du divorce et de la famille' }
]
// -------------------------------------------------- 


// -------------------------------------------------- COMPONENTS
const FAQDivorceFamille = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb 
                className="breadcrumbArea"
                title="Questions relatives au droit du divorce et de la famille"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="singleContentWrap">

                            <h1>Comment divorcer ?</h1><br/>
                            <p>Il existe deux façons de divorcer :</p>
                            <ul>
                                {listItem.map(list => (
                                    <li key={list}>{list}</li>
                                ))}
                            </ul><br/><br/>
                            
                            <h1>Combien de temps faut-il pour divorcer ?</h1><br/>
                            <p>Pour un divorce par consentement mutuel, il faut compter environ 3 mois.<br/>
                            Le divorce judiciaire dépend du tribunal saisi et de la complexité de l’affaire ; en moyenne, cela prend 18 mois.</p><br/><br/>

                            <h1>L’avocat est-il obligatoire ?</h1><br/>
                            <p>L’avocat est obligatoire pour divorcer, que ce soit par consentement mutuel ou devant le Tribunal, que l’on soit demandeur ou défendeur.<br/>
                            Pour les procédures relatives à la fixation de la résidence des enfants, hors divorce, l’avocat n’est pas obligatoire. Il est cependant conseillé en fonction de la complexité de la situation.</p><br/><br/>
                            
                            <h1>Quel est le coût d’un divorce ?</h1><br/>
                            <p> Pour un divorce par consentement mutuel, il faut compter environ 1000 € par personne.<br/>
                            Le coût d’un divorce contentieux va dépendre de la complexité du dossier.</p><br/><br/>

                            <h1>Comment régler une succession litigieuse ?</h1><br/>
                            <p>Il faut d’abord saisir un notaire et justifier de démarches amiables demeurées infructueuses pour régler la succession, avant de saisir le tribunal par votre avocat.<br/>
                            Sachez qu’il existe des possibilités judiciaires pour régler une succession bloquée par l’absence ou la passivité d’un héritier. N’hésitez pas à nous contacter.</p><br/><br/> 
                        </div>
                    </div>
                </div>
            </div>


            <FooterArea />
        </Fragment>
    )
}
// -------------------------------------------------- 


// -------------------------------------------------- EXPORTS
export default FAQDivorceFamille
// --------------------------------------------------