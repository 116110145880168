// -------------------------------------------------- IMPORTS
import React from 'react'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const listVictimes = 
[
    'Responsabilité contractuelle',
    'Responsabilité civile délictuelle',
    'Annulation de contrat',
    'Résolution de contrat',
    'Analyse des contrats',
    'Droit des obligations',
    'Contentieux contractuel',
    'Contrats de distribution (Contrats de franchise, contrats Master Franchise, contrats de concession etc)'
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const DroitContratsConsommationContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentWrap">
                <p>Le droit des contrats régissant tous les rapports dans notre société moderne et étant en constante évolution, vos avocats se forment régulièrement pour vous offrir les meilleures compétences. Ils interviennent à Paris, Lille et dans l’Essonne.
                Vos avocats interviennent dans les domaines suivants :</p>
               
                <div className="avatarWra">
                    <ul>
                        {
                            listVictimes.map(list => (
                                <li key={list}>{list}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default DroitContratsConsommationContent
// --------------------------------------------------