// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import Footer from '../../../../components/Common/Footer'
import RecouvrementCreancesContent from '../../../../components/DomaineDeCompetence/RecouvrementCreancesContent'
import EquipeAvocatsContratConsommation from '../../../../components/EquipeAvocatsContratConsommation'

import './style.scss'

// Images
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'Domaines de compétence', route: '/competences' },
    { name: 'Recouvrement de créances' }
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const RecouvrementCreancesPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb
                className="breadcrumbArea"
                title="Recouvrement de créances"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />
            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <RecouvrementCreancesContent
                                className="singleContentArea"
                                avatar={true}
                            />
                        </div>

                        <EquipeAvocatsContratConsommation
                            title="Nos Avocats Vous Accompagnent"
                            subTitle="RENCONTREZ NOS EXPERTS"
                            className="teamArea"
                            noGutters={ true }
                        />

                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default RecouvrementCreancesPage
// -------------------------------------------------- 