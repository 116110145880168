// -------------------------------------------------- IMPORTS
import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import DroitPenal from '../../images/Skills/DroitPenal.svg'
import DroitDuTravail from '../../images/Skills/DroitDuTravail.svg'
import DroitDivorceFamille from '../../images/Skills/DroitDivorceFamille.svg'
import DroitDesEtrangers from '../../images/Skills/DroitDesEtrangers.svg'
import DroitConsommation from '../../images/Skills/DroitConsommation.svg'
import DroitLocatifImmobilier from '../../images/Skills/DroitLocatifImmobilier.svg'
import RecouvrementCreances from '../../images/Skills/RecouvrementCreances.svg'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const FAQQuestions = 
[
    {
        icon: DroitConsommation,
        text: 'Questions relatives au droit des contrats',
        route: 'faq-droit-contrats'
    },
    {
        icon: DroitDesEtrangers,
        text: 'Questions relatives au droit des étrangers',
        route: 'faq-droit-etrangers'
    },
    {
        icon: DroitDivorceFamille,
        text: 'Questions relatives au droit du divorce et de la famille',
        route: 'faq-droit-famille'
    },
    {
        icon: DroitDuTravail,
        text: 'Questions relatives au droit du travail',
        route: 'faq-droit-travail'
    },
    {
        icon: DroitLocatifImmobilier,
        text: 'Questions relatives au locatif et immobilier',
        route: 'faq-locatif-immobilier'
    },
    {
        icon: DroitPenal,
        text: 'Questions relatives au droit pénal',
        route: 'faq-droit-penal'
    },
    {
        icon: RecouvrementCreances,
        text: 'Questions relatives au recouvrement de créances',
        route: 'faq-recouvrement'
    },
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const FAQTable = ({ className }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">

                    {
                        FAQQuestions.map((question, i) => (
                            <div key={i} className="col-lg-6 col-md-6 col-12">
                                <div className="pricingWrapper">
                                    <div className="skillIcon">
                                        <img src={ question.icon } alt="" />
                                    </div>
                                    
                                    <p>{question.text}</p>

                                    <div className="btnStyle">
                                        <Link to={question.route}>En savoir plus</Link>
                                    </div>

                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default FAQTable
// --------------------------------------------------