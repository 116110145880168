// -------------------------------------------------- IMPORTS
import React from 'react'
import ContactForm from '../ContactForm'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const ConsultationGratuiteForm = ({ className }) => 
{
    return (
        <div className={ className }>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contactUsInfo">
                            <h3>Contactez-nous !</h3><br/>
                            <p>Nous nous engageons à vous répondre dans les meilleurs délais.</p><br/><br/>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="contactUsInfo">
                            <h4>Adresse</h4>
                            <span>140 Avenue du Général De Gaulle - RN7</span><br/>
                            <span>91170 Viry-Châtillon</span>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="contactUsInfo">
                            <h4>Téléphone</h4>
                            <span>Viry-Châtillon: <a href="tel:+33169053399">01.69.05.33.99</a></span><br/>
                            <span>Lille: 03.00.00.00.00</span>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="contactUsInfo">
                            <h4>Email</h4>
                            <a href="mailto:contact@hkhavocats.fr?subject=[HKH-Avocats] Demande de contact">contact@hkhavocats.fr</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default ConsultationGratuiteForm
// --------------------------------------------------