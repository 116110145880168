// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../components/Common/HeaderBottom'
import HeaderTop from '../../../components/Common/HeaderTop'
import Breadcrumb from '../../../components/Common/Breadcrumb'
import Footer from '../../../components/Common/Footer'
import HeaderPicture from '../../../images/Breadcrumb/AvocatViryChatillon.jpg'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const MentionsLegalesPage = () => 
{
    return (    
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb 
                className="breadcrumbArea"
                title="Mentions Légales"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <b>Nom du site :</b> www.hkhavocats.fr<br/>
                            <b>Directeur de la publication :</b> Helain Xavier<br/>
                            <b>Email :</b> contact@hkhavocats.fr<br/><br/>

                            <b>Adresse :</b> 140 Avenue du Général De Gaulle - RN7<br/>
                            91170 - VIRY CHATILLON<br/>
                            <b>Tél :</b> 06.86.63.75.40<br/>
                            <b>Forme juridique :</b> Profession libérale<br/>
                            <b>Raison sociale :</b> SELARL HKH<br/>
                            <b>SIRET :</b> 53518935100013<br/>
                            <b>TVA Intracommunautaire :</b> FR0053558933510001<br/><br/>
                            
                            <h3>POLITIQUE DE CONFIDENTIALITE</h3><br/>
                            La présente politique de confidentialité est fondée sur le Règlement UE 2016/679 relatif à la protection des données (RGPD) ainsi que sur votre consentement éclairé. Elle a pour objectif de vous informer sur le traitement de vos données à caractère personnel. <br/><br/>
                            Cette politique de confidentialité ne s'applique qu'aux renseignements recueillis par le présent site, bien que ce dernier puisse contenir des liens vers d'autres sites externes. Vous comprenez bien que nous ne pouvons vous garantir que ces sites appliquent la même politique que nous en matière de respect de la vie privée, c'est pourquoi nous vous engageons à prendre connaissance des politiques de confidentialité des sites web que vous seriez amené(e) à visiter à partir de celui-ci avant de remettre vos données personnelles.<br/><br/>

                            Nous nous réservons la possibilité de modifier à tout moment cette politique de confidentialité, ces modifications entrant en vigueur immédiatement. Or, à chaque nouvelle utilisation du présent site, vous vous soumettez à la politique de confidentialité en vigueur.<br/><br/>

                            Aussi, nous vous invitons à relire régulièrement cette page afin de maintenir votre consentement.<br/><br/><br/>

                            <h5>Quels renseignements sont collectés sur ce site ?</h5>

                            Lorsque vous visitez un site internet, vous demeurez anonyme. Les informations collectées au cours de votre navigations servent à analyser votre comportement sur ce site et l'usage que vous en avez afin d'améliorer votre expérience utilisateur.<br/><br/>

                            Les autres données personnelles que nous collectons, tels que vos nom, prénom, adresse postale, numéro de téléphone ou adresse mail, ne sont collectées que si vous acceptez de nous les fournir, par exemple lorsque vous adhérez à notre newsletter, dans le cadre de la gestion de votre demande de devis ou si vous souhaitez être recontacté(e).<br/><br/>

                            Nous nous engageons par ailleurs à ne recueillir que les informations strictement nécessaires au traitement de votre demande.<br/><br/><br/>

                            <h5>Pourquoi collectons-nous vos données personnelles ?</h5>

                            Nous collectons vos données personnelles afin de :<br/><br/>

                            <li>Gestion de la relation client (devis, commande, achat, demande de renseignements...)</li>
                            <li>Suivre notre information (newsletter, ...)</li><br/><br/>

                            <h5>Comment vos données à caractère personnel sont-elles utilisées ?</h5>

                            Nous nous engageons à respecter la stricte confidentialité de vos données personnelles et de les utiliser uniquement pour les finalités pour lesquelles vous avez accepté de nous les communiquer. Toutefois, elles ne seront pas conservées plus longtemps que nécessaire, soit durant toute la durée de notre collaboration augmentée du délai de prescription légale. Après quoi, elles seront détruites.<br/><br/>

                            Nous pouvons être amenés à les transférer à des tiers tels que notre prestataire informatique, notre hébergeur, ... Nous vous garantissons cependant qu'elles seront transférées selon un protocole sécurisé et traitées par ces derniers avec le plus grand soin et le maximum de sécurité.<br/><br/><br/>

                            <h5>Quels sont vos droits ?</h5>

                            Vous avez le droit de ne pas vouloir nous communiquer de données personnelles. Dans ce cas, vous pourrez naviguer sur ce site, cependant vous n'aurez pas accès à l'ensemble de ses fonctionnalités (adhérer à la newsletter par exemple, demander à être recontacté(e), ...)<br/><br/>

                            Vous avez un droit d'accès, de correction et de retrait de vos données personnelles. Vous pouvez exercer ce droit, par exemple en les mettant à jour, en nous demandant de les rectifier, de les compléter, de les mettre à jour, de les verrouiller ou en nous demandant à ce que vos renseignements ne figurent plus sur notre liste de diffusion, en nous contactant à l'adresse suivante contact@avocats-helain.com et en joignant à votre demande une copie d'une pièce d'identité en cours de validité (carte d'identité, passeport ou permis de conduire).<br/><br/>

                            Vous avez le droit d'introduire une réclamation auprès de la CNIL si vous l'estimez nécessaire. Dans ce cas, nous vous invitons à consulter leur site internet aux fins de connaître les modalités d'exécution (www.cnil.fr)<br/><br/>

                            Vous avez également un droit à la portabilité de vos données à caractère personnel. Vous pouvez, à la même adresse que précédemment, demander à ce que l'on vous restitue sur un support utilisable toutes les données personnelles que vous nous avez fournies en vue par exemple de les transférer à un tiers.<br/><br/>

                            Enfin, vous avez un droit d'opposition au traitement de vos données pour des motifs légitimes ainsi qu'un droit d'opposition à ce que ces données soient utilisées à d'autres fins que celles auxquelles vous avez consenti(e). Vous pouvez user de ce droit en contactant le responsable de traitement à l'adresse rappelée ici contact@hkhavocats.fr.<br/><br/><br/>

                            <h5>Et si j'ai encore des questions ?</h5>

                            Pour toutes questions cernant cette politique de confidentialité ou vos données à caractère personnel, n'hésitez pas à nous contacter à contact@avocats-helain.com ou par courrier 140 Avenue du Général De Gaulle - RN7 91170 VIRY CHATILLON.<br/><br/>

                            Hébergement : AXECIBLES, société par actions simplifiée au capital de 180.000 euros, dont le siège social est situé : Business Park 87, rue du Molinel CS 22058 59702 Marcq en Baroeul Cedex, immatriculée au Registre du Commerce et des Sociétés de LILLE METROPOLE sous le numéro 440 043 776 00028 Code NAF 6202B<br/><br/>
                            Tél. : 03.59.57.51.98

                            L'utilisateur du site Internet reconnaît disposer de la compétence et des moyens nécessaires pour accéder et utiliser ce site. Il reconnaît également avoir pris connaissance de la présente notice légale et s'engage à la respecter.<br/><br/>

                            Pour retrouver l'ensemble des informations relatives à l'utilisation et au paramétrage des cookies, veuillez cliquer ici : « En savoir plus et paramétrer les cookies ».<br/><br/>

                            Les informations recueillies via le présent site proviennent de l'enregistrement volontaire d'une adresse courriel fournie par l'internaute. Elles lui permettront de recevoir une documentation, de se renseigner sur un point de droit quelconque ou d'être contacté par l'avocat. Ces informations ne sont jamais communiquées à un tiers. Les traitements réalisés via le présent site étant constitués en vue d'information sont des traitements courants qui ne sont pas susceptibles de porter atteinte à la vie privée des personnes dans le cadre de leur utilisation régulière. Ces traitements peuvent bien sûr avoir pour finalités la constitution de fichiers d'adresses à des fins d'informations ou de communication externe se rapportant au but ou à l'activité du directeur de publication.<br/><br/>

                            L'utilisateur est informé que lors de ses visites sur le site, un cookie peut s'installer automatiquement sur son logiciel de navigation. Un cookie est un élément qui ne permet pas d'identifier l'utilisateur mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site Internet. L'utilisateur pourra désactiver ce cookie par l'intermédiaire des paramètres figurant au sein de son logiciel de navigation.<br/><br/>

                            L'utilisateur dispose d'un droit d'accès, de modification, de rectification et de suppression des données qui le concernent (article 34 de la loi " Informatique et Libertés ").<br/>
                            Pour l'exercer, l'utilisateur peut s'adresser auprès du Directeur de la publication visé plus haut.<br/><br/>

                            Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l'autorisation expresse de l'exploitant du site Internet est interdite et constituerait une contrefaçon sanctionnée par les article L 335-2 et suivants du Code de la propriété intellectuelle.<br/><br/>

                            Il en est de même des éventuelles bases de données figurant sur le site Internet qui sont protégées par les dispositions de la loi du 11 juillet 1998 portant transposition dans le Code de la propriété intellectuelle (CPI) de la directive européenne du 11 mars 1996 relative à la protection juridique des bases de données.<br/><br/>

                            Les marques de l'exploitant du site Internet et de ses partenaires, ainsi que les logos figurant sur le site sont des éléments protégés par les dispositions du droit de la propriété intellectuelle et ne peuvent faire l'objet, sans consentement du Directeur de la publication, d'aucune reproduction ni représentation partielle ou totale.<br/><br/>

                            Les liens hypertextes mis en place dans le cadre du site Internet en direction d'autres ressources présentes sur le réseau de l'Internet, et notamment vers ses partenaires ont fait l'objet d'une autorisation préalable expresse et écrite.<br/><br/>

                            Les utilisateurs visiteurs du site Internet ne peuvent mettre en place un hyperlien en direction de ces sites sans l'autorisation expresse et préalable de l'exploitant du site Internet.<br/><br/>

                            Conformément aux articles L.612-1 et suivants issus de l'ordonnance n°2016-301 du 14 mars 2016, vous avez la possibilité en cas de litige avec un avocat de recourir gratuitement au Médiateur de la Consommation qui sera le Médiateur National près du Conseil National et dont les coordonnées sont les suivantes : CNB - Médiateur à la Consommation, 22 rue de Londres, 75009 PARIS.<br/><br/>

                            Le présent site est la propriété de LOCAM, réalisé par AXECIBLES, établissement situé au Business Park - 87, rue du Molinel - CS 22058 - 59702 Marcq-en-Baroeul Cedex. - devis@axecibles.com
                        </div>
                    </div>
                </div>
            </div>
                    
            <Footer/>
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default MentionsLegalesPage
// --------------------------------------------------