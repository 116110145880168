// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import Footer from '../../../../components/Common/Footer'
import ConsultationGratuiteForm from '../../../../components/Forms/ConsultationGratuiteForm'

// Images
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'
import LeaBostPicture from '../../../../images/AttorneysSingle/LeaBostSquare.jpg'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'L\'équipe', route: '/avocats' },
    { name: 'Maître Léa BOST' }
]

const AvocatInfos = 
[
    { level: 'Avocate collaboratrice', text: '' },
    { level: 'Domaines de compétence : ', text: 'Droit de la famille, Droit pénal, Droit des contrats, Recouvrement de créances' },
    { level: 'Téléphone : ', text: '01.69.05.33.99' },
    { level: 'Email : ', text: 'contact@hkhavocats.fr' },
]

const Diplomes = 
[
    'Master de droit privé et sciences criminelles',
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const LeaBost = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>

            <Breadcrumb
                className="breadcrumbArea"
                title="Maître Léa BOST"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className='singleTeamArea'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamImgWrap">
                                        <div className="singleTeamImg">
                                            <img src={ LeaBostPicture } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamContent">
                                        <h4>Maître Léa BOST</h4>
                                        <ul className="teamMemberInfo">
                                            {
                                                AvocatInfos.map((info, i) => (
                                                    <li key={ i }>
                                                        <span>{ info.level }</span>
                                                        { info.text }
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="singleTeamInfo">
                                        <h3>Expérience professionnelle</h3>
                                        <p>Après avoir obtenu un Master de droit privé et sciences criminelles à l’université de Paris X Nanterre la Défense, elle intègre la Haute Ecole des Avocats Conseils de Versailles (HEDAC).</p>

                                        <h5>Formations / Diplômes</h5>
                                        <ul>
                                            { 
                                                Diplomes.map(diplome => (
                                                    <li key={ diplome }>{ diplome }</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConsultationGratuiteForm
                className="contactArea"
            />

            <Footer />
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default LeaBost
// --------------------------------------------------