// -------------------------------------------------- IMPORTS
import React from 'react'
import { Link } from 'react-router-dom'
import HKHLogo from '../../../images/Logo/LogoHKHAvocats.png'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const FooterLinks = 
[
    {
        title: 'Pages', 
        menus: 
        [
            { name: 'Accueil', route: '/' },
            { name: 'Le cabinet', route: 'cabinet' },
            { name: 'L\'équipe', route: 'avocats' },
            { name: 'Domaines de compétence', route: 'competences' },
            { name: 'FAQ', route: 'faq' },
            { name: 'Contact', route: 'contact' },
        ]
    },
    {
        title: 'Domaines', 
        menus: 
        [
            { name: 'Droit du divorce et de la famille', route: 'droit-divorce-et-famille' },
            { name: 'Droit pénal', route: 'droit-penal' },
            { name: 'Droit des étrangers', route: 'droit-des-etrangers' },
            { name: 'Droit du travail', route: 'droit-du-travail' },
            { name: 'Droit locatif et immobilier', route: 'droit-locatif-et-immobilier' },
            { name: 'Droit des contrats et consommation', route: 'droit-contrats-et-consommation' },
            { name: 'Recouvrement de créances', route: 'recouvrement-de-creances' },
        ]
    },
    {
        title: 'Contactez nous', menus: [
            { name: '140 Avenue du Général De Gaulle - RN7,' },
            { name: '91170 Viry-Châtillon', },
            { name: 'France', },
            { name: 'Téléphone : 01.69.05.33.99', },
            { name: 'Email : contact@hkhavocats.fr', },
        ]
    },
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const Footer = () => 
{
    return (
        <footer className="footerArea">
            <div className="footerTopArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footerLogo">
                                <Link to="/">
                                    <img src={ HKHLogo } alt="" />
                                </Link>
                                <p>20 personnes compétentes et 40 ans d’expérience à votre service.</p>
                            </div>
                        </div>
                        {FooterLinks.map((menu, i) => (
                            <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footerWrap">
                                    <h3>{menu.title}</h3>
                                    <ul>
                                        {menu.menus.map((item, i) => (
                                            <li key={i}>{item.route ? <Link to={`/${item.route}`}>{item.name}</Link> : `${item.name}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="footerBottomArea">
                <div className="container">
                    <div className="footerBottomContent">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-12">
                                <span><Link to="/mentions-legales">Mentions Légales</Link> | © 2022 HKH-AVOCATS. Tous droits réservés.</span>
                            </div>
                            <div className="col-md-4 col-sm-2 col-12">
                                <ul className="socialListFooter">
                                    <li><a href="https://www.facebook.com/hkh-avocats/?ti=as" target="_blank" rel="noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i> </a></li>                                    
                                    <li><a href="https://www.linkedin.com/company/hkh-avocats/about/" target="_blank" rel="noreferrer"><i className="fa fa-linkedin" aria-hidden="true"></i> </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
// --------------------------------------------------

// -------------------------------------------------- EXPORTS
export default Footer
// --------------------------------------------------