// -------------------------------------------------- IMPORTS
import React from 'react'
import { Phone, Clock } from 'react-feather';
import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const HeaderTop = props => 
{
    // ---------------------------------------------- JSX
    return (
        <div className={ props.className }>
            <div className="container">
                <div className="headerTopMainWrapper">
                    <div className="row">

                        <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                            <ul className="d-flex Subtitle">
                                <li><i aria-hidden="true"></i>AVOCATS PARIS-LILLE</li>
                            </ul>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
                            <ul className="HeaderContact">
                                <li>
                                    <Phone className="Icon" color="#CDBA6D" size={20} />
                                    <b>Viry-Châtillon:</b> <a href="tel:+33169053399">01.69.05.33.99</a>
                                </li>
                                <li><b>Lille:</b> 03.00.00.00.00</li>
                                <li>
                                    <Clock className="Icon" color="#CDBA6D" size={20} />
                                    9:00 - 18:00
                                </li>
                            </ul>
                        </div>                     
                        
                        <div className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                            <div className="btnStyle text-right">
                                <a href="contact">Première consultation gratuite</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    // ----------------------------------------------
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default HeaderTop
// --------------------------------------------------