// -------------------------------------------------- IMPORTS
import React from 'react'
import { Link } from 'react-router-dom'

import HKHAbout from '../../images/HKHPropos.jpg'

import './style.scss'
// -------------------------------------------------- 


// -------------------------------------------------- COMPONENT
const CabinetInfo = ({ subTitle, title, className, paragraphs, images, orderLast }) => 
{
    return (
        <div className={className}>

            <div className="container">
                <div className="row">
                    <div className={ orderLast ? `${ orderLast } col col-lg-6`:'col col-lg-6' }>
                        <div className="aboutImageWrap">
                            <img src={ HKHAbout } alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="aboutContent">
                            { subTitle && <span>{ subTitle }</span> }
                            <h2>{title}</h2>
                            { 
                                paragraphs.map((paragraph, i) => (
                                    <p key={i}>{paragraph.text}</p>
                                ))
                            }
                            <div className="btnStyle">
                                <Link to='/competences'>Nos compétences</Link>
                            </div><br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default CabinetInfo
// --------------------------------------------------