// -------------------------------------------------- IMPORTS
import React from 'react'
import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const listItem = 
[
    'Divorce : qu’il soit par consentement mutuel ou devant le Juge aux affaires familiales,',
    'Garde d’enfants : résidence exclusive ou alternée,',
    'Pension alimentaire,',
    'Successions litigieuses,',
    'Partages / cessation d’indivision : après un divorce, la dissolution d’un Pacs, une séparation de concubins ou dans le cadre d’une succession,',
    'Adoptions,',
    'Contestations de paternité,',
    'Tutelles.',
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const DroitFamilleContent = ({ className, avatar, image }) => {
    return (
        <div className={ className }>

            <div className="singleContentWrap">
                <p>Formés en continu aux évolutions rapides de cette matière, vos avocats vous assistent, conseillent et défendent dans toutes les procédures concernant la famille et son patrimoine, à Paris, Lille et dans l’Essonne.</p>
                { 
                    avatar && (
                        <div className="avatarWra">
                            <div className="row">
                                <div className="col-md-8">
                                    <ul>
                                        {listItem.map(list => (
                                            <li key={list}>{list}</li>
                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default DroitFamilleContent
// --------------------------------------------------