// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import Footer from '../../../../components/Common/Footer'
import ConsultationGratuiteForm from '../../../../components/Forms/ConsultationGratuiteForm'

// Images
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'
import CamilleJamiPicture from '../../../../images/AttorneysSingle/CamilleJamiSquare.jpg'


import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'L\'équipe', route: '/avocats' },
    { name: 'Maître Camille JAMI' }
]

const AvocatInfos = 
[
    { level: 'Avocat collaborateur', text: '' },
    { level: 'Domaines de compétence : ', text: 'Droit social, Droit de la famille, Droit pénal, Droit immobilier' },
    { level: 'Téléphone : ', text: '01.69.05.33.99' },
    { level: 'Email : ', text: 'contact@hkhavocats.fr' },
]

const Diplomes = 
[
    'Master 2 Droit privé et sciences criminelles',
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const CamilleJami = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>

            <Breadcrumb
                className="breadcrumbArea"
                title="Maître Camille JAMI"
                breadcrumbMenu={ BreadcumbMenu }
                background={ HeaderPicture }
            />
            <div className='singleTeamArea'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamImgWrap">
                                        <div className="singleTeamImg">
                                            <img src={ CamilleJamiPicture } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamContent">
                                        <h4>Maître Camille JAMI</h4>
                                        <ul className="teamMemberInfo">
                                            {
                                                AvocatInfos.map((info, i) => (
                                                    <li key={i}>
                                                        <span>{info.level}</span>
                                                        {info.text}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="singleTeamInfo">
                                        <h3>Expérience professionnelle</h3>
                                        <p>Après avoir obtenu un Master Droit privé et sciences criminelles à l’Université Lyon 3 et un Master 2 Droit privé et sciences criminelles à l’Université Le Havre Normandie, il intègre la Haute Ecole des Avocats Conseils de Versailles (HEDAC).</p>

                                        <h5>Diplômes / Formations</h5>
                                        <ul>
                                            { 
                                                Diplomes.map(diplome => (
                                                    <li key={ diplome }>{ diplome }</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConsultationGratuiteForm
                className="contactArea"
            />

            <Footer />
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default CamilleJami
// --------------------------------------------------