// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import FooterArea from '../../../../components/Common/Footer'

import './style.scss'

// Images
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'FAQ', route: '/faq' },
    { name: 'Questions relatives au locatif et immobilier' }
]

const listItem1 = 
[
    'Déposer plainte auprès du commissariat de votre domicile, qui ira constater l’occupation illicite et demander à la Préfecture de les mettre en demeure de quitter les lieux, dans un délai qui ne peut être inférieur à 24 heures, faute de quoi, l’évacuation forcée aura lieu.',
    'Parallèlement, il peut être utile, en cas d’inaction des pouvoirs publics, de saisir un huissier afin qu’il constate cette occupation illicite (avec autorisation préalable du Juge, que notre cabinet vous obtiendra). Au vu de ce constat d’huissier, nous saisirons alors en urgence le tribunal aux fins d’expulsion sans délai, y compris pendant la période hivernale.',
]

const listItem2 = 
[
    'Soit après jugement (hypothèque définitive)',
    'Soit, à défaut de jugement, après en avoir obtenu l’autorisation auprès du Juge de l’Exécution (hypothèque provisoire).',
]
// -------------------------------------------------- 


// -------------------------------------------------- COMPONENTS
const FAQDroitLocatifImmobilier = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb 
                className="breadcrumbArea"
                title="Questions relatives au locatif et immobilier"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="singleContentWrap">
                            <h1>Mon locataire a cessé de payer les loyers. Que faire ?</h1><br/>
                            <p>Il faut d’abord saisir un huissier de justice qui délivrera un commandement de payer visant la clause résolutoire prévue au bail.<br/>
                            Sans paiement par le locataire de la somme due, dans le délai de deux mois (un mois pour un local commercial), notre cabinet lancera une assignation aux fins de résiliation du bail et expulsion.<br/>
                            L’expulsion est ensuite diligentée par l’huissier de justice.</p><br/><br/>
                            
                            <h1>Mon logement est occupé par des squatters, quelles solutions existe-t-il ?</h1>
                            <br/><p>Dès que vous êtes informé de cette situation, vous devez :</p>
                            <ul>
                                {
                                    listItem1.map(list => (
                                        <li key={list}>{list}</li>
                                    ))
                                }
                            </ul><br/><br/>

                            <h1>Qu’est-ce qu’une hypothèque judiciaire ?</h1><br/>
                            <p>En cas de non-paiement d’une somme d’argent, l’hypothèque prise sur le bien immobilier de votre débiteur vous permet de garantir votre créance.<br/>
                            En cas de vente ou de décès, vous serez ainsi payé par préférence aux simples créanciers.<br/>
                            Notre cabinet procède quotidiennement à l’inscription d’hypothèques :</p>
                            <ul>
                                {
                                    listItem2.map(list => (
                                        <li key={list}>{list}</li>
                                    ))
                                }
                            </ul><br/>
                            <p>Le coût de l’inscription elle-même (hors honoraires) est d’un peu moins d’1% du montant de la créance.<br/>
                            Si à l’inverse, un créancier a inscrit une hypothèque sur votre bien immobilier, notre cabinet est à votre disposition pour vérifier si elle a été valablement inscrite et, à défaut, en obtenir la mainlevée.</p><br/><br/>
                            
                            <h1>Que faire en cas de saisie immobilière ?</h1><br/>
                            <p>Avant cela, dès que vous recevez des relances, une mise en demeure de votre créancier et encore plus dès délivrance du commandement de saisie immobilière, il vous faut prendre attache avec celui-ci pour proposer une solution amiable et éviter la saisie à la Barre du tribunal.<br/>
                            A défaut, vous serez assigné pour une audience dite d’orientation, à laquelle vous aurez encore la possibilité d’éviter une vente judiciaire, mais en proposant une vente amiable de votre bien.<br/>
                            Un avocat inscrit au barreau du lieu de la saisie est obligatoire pour vous représenter.</p> 
                        </div>
                    </div>
                </div>
            </div>
            <FooterArea />
        </Fragment>
    )
}
// -------------------------------------------------- 


// -------------------------------------------------- EXPORTS
export default FAQDroitLocatifImmobilier
// --------------------------------------------------