// -------------------------------------------------- IMPORTS
import React from 'react'
import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const ContactNumber = props => 
{
    return (
        <div className={props.className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-6">
                        <h2>Besoin d'un avocat à Paris ou à Lille ?</h2>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="btnStyle btnStyleNumber">
                            <a href="tel:+33169053399">01.69.05.33.99</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// -------------------------------------------------- IMPORTS


// -------------------------------------------------- EXPORTS
export default ContactNumber
// --------------------------------------------------