// -------------------------------------------------- IMPORTS
import React from 'react'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const listVictimes = 
[
    'Location commerciale et bail d’habitation : loyers impayés, expulsion, procédures urgentes contre squatters, …',
    'Permis de construire, urbanisme',
    'Litiges de voisinage (limites de propriétés, empiètements, vues directes etc )',
    'Saisies immobilières, hypothèques sur bien immobilier.'
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const DroitLocatifImmobilierContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentWrap">
                <p>Forts d’une expérience de plus de 40 ans en droit immobilier, formés en continu aux évolutions de cette matière, vos avocats vous assistent, conseillent et défendent à Paris, Lille et dans l’Essonne dans toutes les procédures concernant :</p>
               
                <div className="avatarWra">
                    <ul>
                        {
                            listVictimes.map(list => (
                                <li key={list}>{list}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default DroitLocatifImmobilierContent
// --------------------------------------------------