// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import FooterArea from '../../../../components/Common/Footer'
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'FAQ', route: '/faq' },
    { name: 'Questions relatives au droit des contrats' }
]
// -------------------------------------------------- 


// -------------------------------------------------- COMPONENTS
const FAQDroitDesContrats = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb 
                className="breadcrumbArea"
                title="Questions relatives au droit des contrats"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className="singleArea">
                <div className="container">
                    <div className="row">
                    <div className="singleContentWrap">

                        <h1>Qu’est-ce que le droit des contrats ?</h1><br/>
                        <p>Que vous achetiez un bien quelconque, que vous inscriviez vos enfants à l’école ou que vous souscriviez un contrat d’assurance, tous les rapports en société font l’objet d’un ou plusieurs contrats.<br/> <br/>
                        Votre avocat défendra vos droits face à tous les problèmes du quotidien. </p><br/><br/>
                        
                        <h1>Pourquoi faire appel à un avocat en droit des contrats ?</h1><br/>
                        <p>Le droit des contrats est en constante évolution puisque les Lois évoluent en même temps que la société. <br/><br/>
                        Pour défendre au mieux vos intérêts, votre avocat se forme régulièrement pour être à la pointe de l’évolution du droit et vous apportez le meilleur des services.</p>

                        
                    </div>
                    </div>
                </div>
            </div>


            <FooterArea />
        </Fragment>
    )
}
// -------------------------------------------------- 


// -------------------------------------------------- EXPORTS
export default FAQDroitDesContrats
// --------------------------------------------------