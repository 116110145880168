// -------------------------------------------------- IMPORTS
import React, { Fragment } from "react";
import Slider from "react-slick";
import { NavLink } from 'react-router-dom'
import SectionTitle from '../Common/Title'

import './style.scss'

// Images
import XavierHelain from '../../images/Attorneys/XavierHelain.jpg'
import AurelieNosal from '../../images/Attorneys/AurelieNosal.jpg'
import YannHesteau from '../../images/Attorneys/YannHesteau.jpg'
import LeaBost from '../../images/Attorneys/LeaBost.jpg'
import OlivierHascoet from '../../images/Attorneys/OlivierHascoet.jpg'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const Avocats = 
[
    {
        name: 'Maître Xavier HELAIN', role: 'Avocat associé', image: XavierHelain, route: 'avocat-xavier-helain'
    },
    {
        name: 'Maître Olivier HASCOËT', role: 'Avocat associé', image: OlivierHascoet, route: 'avocat-olivier-hascoet'
    },
    {
        name: 'Maître Aurélie NOSAL', role: 'Avocate collaboratrice', image: AurelieNosal, route: 'avocat-aurelie-nosal'
    },
    {
        name: 'Maître Yann HESTEAU', role: 'Avocat collaborateur', image: YannHesteau, route: 'avocat-yann-hesteau'
    },
    {
        name: 'Maître Léa BOST', role: 'Avocate collaboratrice', image: LeaBost, route: 'avocat-lea-bost'
    },
]

const SliderSettings = 
{
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
// -------------------------------------------------- 


// -------------------------------------------------- COMPONENT
const EquipeAvocatsContratConsommation = ({ className, title, subTitle, slider, noGutters }) => 
{
    return (
        <div className={className}>
            <div className="container">
                <div className={!noGutters ? 'row' : 'row no-gutters'}>
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>

                    {
                        slider ? (
                            <div className="col-12">
                                <Slider className="teamSlideArea" {...SliderSettings}>
                                    {Avocats.map((team, i) => (
                                        <div key={i} className="teamWrapper">
                                            <NavLink to={team.route}>
                                            <div className="teamImage">
                                                <img src={team.image} alt="" />
                                            </div>
                                            <div className="teamContent">
                                                <h3>{team.name}</h3>
                                                <span>{team.role}</span>
                                            </div>
                                            </NavLink>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        ) : (
                        <Fragment>
                            {Avocats.map((avocat, i) => (
                                <div key={i} className="col-lg-4 col-md-6 col-12">
                                    <div className="teamWrapper">
                                        <NavLink to={ avocat.route }>
                                            <div className="teamImage">
                                            
                                                <img src={avocat.image} alt="" />
                                                
                                            </div>
                                            <div className="teamContent">
                                                <h3>{avocat.name}</h3>
                                                <span>{avocat.role}</span>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            ))}
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default EquipeAvocatsContratConsommation
// --------------------------------------------------