// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderTop from '../../../components/Common/HeaderTop'
import HeaderBottom from '../../../components/Common/HeaderBottom'
import Breadcrumb from '../../../components/Common/Breadcrumb'
import FooterArea from '../../../components/Common/Footer'
import ContactForm from '../../../components/Forms/ContactForm'
import HeaderPicture from '../../../images/Breadcrumb/AvocatViryChatillon.jpg'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'Contact', },
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const Contact = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>

            <Breadcrumb
                className="breadcrumbArea"
                title="Contact"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className="contactUsPageArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contactUsInfo">
                                <h3>Contactez-nous !</h3>
                                <p>Nous nous engageons à vous répondre dans les meilleurs délais.</p><br/>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contactUsInfo">
                                <h4>Adresse</h4>
                                <span>140 Avenue du Général De Gaulle - RN7</span>
                                <span>91170 Viry-Châtillon</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contactUsInfo">
                                <h4>Téléphone</h4>
                                <span>Viry-Châtillon: <a href="tel:+33169053399">01.69.05.33.99</a></span>
                                <span>Lille: 03.00.00.00.00</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contactUsInfo">
                                <h4>Email</h4>
                                <a href="mailto:contact@hkhavocats.fr?subject=[HKH-Avocats] Demande de contact">contact@hkhavocats.fr</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterArea />
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default Contact
// --------------------------------------------------