// -------------------------------------------------- IMPORTS
import React from 'react'
import './style.scss'
import SectionTitle from '../../Common/Title'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const listInterviennent = 
[
    'En audition libre,',
    'En garde à vue,',
    'En instruction,',
    'Contre les placements en détention provisoire,',
    'Pour les sorties de détention provisoire,',
    'Devant le tribunal correctionnel de Paris et sa région, Lille et sa région',
    'Devant les Cours d’appel de Paris et de Douai'
]

const listVictimes = 
[
    'Victimes de violences conjugales,',
    'Victimes de violences sexuelles,',
    'Victimes de vol,',
    'Victimes d’escroquerie,',
    'Victimes de violences physiques,',
    'Victimes d’un accident de la route',
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const DroitPenalContent = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentWrap">
                <p>Diplômés en droit pénal, vos avocats seront à vos côtés quelles que soient les poursuites diligentées contre vous à Paris, Lille et dans l’Essonne.</p>
                <p>Particulièrement attachés à la procédure qui peut vous faire éviter un procès, vos Avocats sont constamment à la recherche des failles que peuvent comporter les dossiers.</p>
                <p>Ils interviennent :</p>
               
                <div className="avatarWra">
                    <ul>
                        {
                            listInterviennent.map(list => (
                                <li key={list}>{list}</li>
                            ))
                        }
                    </ul>
                </div><br/><br/>

                <SectionTitle
                    title="Victimes"
                    subTitle=""
                />
                <p>Vos Avocats vous épauleront si vous êtes victimes d’une infraction pénale quelle qu’elle soit, à Paris, Lille et dans l’Essonne.</p>
                <p>Ils vous écouteront et vous épaulerons dans l’épreuve que constitue la procédure pénale afin d’obtenir des dommages et intérêts en réparation de votre préjudice.</p>

                <div className="avatarWra">
                    <ul>
                        {
                            listVictimes.map(list => (
                                <li key={list}>{list}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default DroitPenalContent
// --------------------------------------------------