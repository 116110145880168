// -------------------------------------------------- IMPORTS
import React from "react";
import SectionTitle from '../../Common/Title'
import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- JSX
const SkillsDomains = ({ className, Title, SubTitle, SkillsDomains }) => 
{
    return (
        <div className={ className }>
            <div className="container">
                <div className="row">

                    <div className="col-12">
                        <SectionTitle
                            title={ Title }
                            subTitle={ SubTitle }
                        />
                    </div>

                    { 
                        SkillsDomains.map((skilldomain, index) => (
                            <div key={ index } className="col-lg-6 col-md-6">
                                <div className="skillWrap">
                                    <div className="skillIcon">
                                        <img src={ skilldomain.icon } alt="" />
                                    </div>
                                    <div className="skillContent">
                                        <h3>{ skilldomain.title }</h3>
                                        <p>{ skilldomain.content }</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default SkillsDomains
// --------------------------------------------------