// -------------------------------------------------- IMPORTS
import React from "react";
import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const SectionTitle = ({ title, subTitle }) => 
{
    return (
        <div className="sectionTitle">
            <span>{ subTitle }</span>
            <h2>{ title }</h2>
        </div>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default SectionTitle
// --------------------------------------------------