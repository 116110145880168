// -------------------------------------------------- IMPORTS
import React, { Component } from 'react'
import axios from 'axios'
import Joi from 'joi-browser'
import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
class ContactForm extends Component 
{
    // ---------------------------------------------- DATAS
    state = 
    {
        name: '',
        phone: '',
        email: '',
        domain: 'Droit du divorce et de la famille',
        description: '',
        error: {}
    }

    // Joi browser rules
    schema = 
    {
        email: Joi.string().email({ minDomainAtoms: 2 }).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) 
                {
                    case "string.email":
                        err.message = 'L\'email doit être un email valide.';
                        break;
                    default:
                        err.message = 'L\'email ne peut pas être vide.';
                        break;
                }
            });
            return errors;
        }),
        phone: Joi.number().integer().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) 
                {
                    default:
                        err.message = 'Le numéro de téléphone est vide ou incorrect.';
                        break;
                }
            });
            return errors;
        }),
        name: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) 
                {
                    default:
                        err.message = 'Le nom ne peut pas être vide.';
                        break;
                }
            });
            return errors;
        }),
        domain: Joi.string(),
        description: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) 
                {
                    default:
                        err.message = 'La description ne peut pas être vide.';
                        break;
                }
            });
            return errors;
        }),
    }
    // ----------------------------------------------


    // ---------------------------------------------- HANDLERS
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMessage = this.validationProperty(event);
        if (errorMessage) 
        {
            error[event.target.name] = errorMessage;
        } 
        else 
        {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };

    // handleChange = (value) => {
    //     this.setState({
    //         country: value
    //     })
    // };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = 
        {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            description: this.state.description,
        }
        const { error } = Joi.validate(form, this.schema, options)
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message
        return errors;
    };

    submitHandler = event => {
        event.preventDefault()
        const error = this.validate();
        if (error) 
        {
            this.setState({
                error: error || {}
            })
        } 
        else 
        {
            // e.preventDefault();
            axios({
            method: "POST", 
            url:"/send", 
            data:  this.state
            }).then((response)=>{
            if (response.data.status === 'success'){
                alert("Message Sent."); 
                // this.resetForm()
            }else if(response.data.status === 'fail'){
                alert("Message failed to send.")
            }
            })
            this.setState({
                name: '',
                phone: '',
                email: '',
                domain: '',
                description: '',
            })
        }
    }
    // ----------------------------------------------


    // ---------------------------------------------- JSX
    render() 
    {
        const options = [
            { level: 'Droit du divorce et de la famille', value: 'Droit du divorce et de la famille' },
            { level: 'Droit pénal', value: 'Droit pénal' },
            { level: 'Droit des étrangers', value: 'Droit des étrangers' },
            { level: 'Droit du travail', value: 'Droit du travail' },
            { level: 'Droit locatif et immobilier', value: 'Droit locatif et immobilier' },
            { level: 'Droit des contrats et consommation', value: 'Droit des contrats et consommation' },
            { level: 'Recouvrement de créances', value: 'Recouvrement de créances' },
        ]

        return (
            <form onSubmit={ this.submitHandler } className='contactForm'>
                <div className="row">

                    <div className="col-sm-6 col-md-12 col-lg-12 col-12">
                        <div className="formInput">
                            <input
                                placeholder="Nom"
                                value={ this.state.name }
                                name="name"
                                onChange={ this.changeHandler }
                                className="form-control"
                                type="text" />
                            { this.state.error.name && <p>{ this.state.error.name }</p> }
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-12 col-lg-12 col-12">
                        <div className="formInput">
                            <input
                                placeholder="Téléphone"
                                value={ this.state.phone }
                                name="phone"
                                onChange={ this.changeHandler }
                                className="form-control"
                                type="phone" />
                            { this.state.error.phone && <p>{ this.state.error.phone }</p> }
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-12 col-lg-12 col-12">
                        <div className="formInput">
                            <input
                                placeholder="Email"
                                value={this.state.email}
                                name="email"
                                onChange={this.changeHandler}
                                className="form-control"
                                type="email" />
                            { this.state.error.email && <p>{ this.state.error.email }</p> }
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-12 col-lg-12 col-12">
                        <div className="formInput">
                            <select
                                value={ this.state.domain }
                                className="form-control"
                                onChange={ this.changeHandler }
                                name="domain">
                                { options.map(option => (
                                    <option
                                        key={ option.value }
                                        value={ option.value }
                                    >
                                        { option.level }
                                    </option>
                                )) }
                            </select>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="formInput">
                            <textarea
                                className="form-control"
                                value={ this.state.description }
                                onChange={ this.changeHandler }
                                placeholder="Description de votre demande..."
                                name="description" />
                            { this.state.error.description && <p>{ this.state.error.description }</p>}
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit">Envoyer</button>
                    </div>
                </div>
            </form>
        )
    }
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default ContactForm
// --------------------------------------------------