// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import Footer from '../../../../components/Common/Footer'
import ContactFreeForm from '../../../../components/Forms/ConsultationGratuiteForm'

// Images
import AurelieNosalPicture from '../../../../images/AttorneysSingle/AurelieNosalSquare.jpg'
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'

import './style.scss'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'L\'équipe', route: '/avocats' },
    { name: 'Maître Aurélie NOSAL' }
]

const AvocatInfos = 
[
    { level: 'Avocate collaboratrice ', text: '' },
    { level: 'Domaines de compétence : ', text: 'Droit des contrats, Droit de la famille, Droit des étrangers, Recouvrement de créances.' },
    { level: 'Téléphone : ', text: '01.69.05.33.99' },
    { level: 'Email : ', text: 'contact@hkhavocats.fr' },
]

const Diplomes = 
[
    'Master 2 Contentieux'
]
// --------------------------------------------------


// -------------------------------------------------- COMPONENT
const AurelieNosal = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>

            <Breadcrumb
                className="breadcrumbArea"
                title="Maître Aurélie NOSAL"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className='singleTeamArea'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamImgWrap">
                                        <div className="singleTeamImg">
                                            <img src={ AurelieNosalPicture } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamContent">
                                        <h4>Maître Aurélie NOSAL</h4>
                                        <ul className="teamMemberInfo">
                                            {
                                                AvocatInfos.map((info, i) => (
                                                    <li key={ i }>
                                                        <span>{ info.level }</span>
                                                        { info.text }
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="singleTeamInfo">
                                        <h3>Expérience professionnelle</h3>
                                        <p>Après avoir obtenu un Master Carrières judiciaires à l’Université d’Assas et un Master 2 Contentieux à l’Université de Paris 13, elle intègre l’Ecole d’avocat de Paris (EFB).</p>

                                        <h5>Diplômes / Formations</h5>
                                        <ul>
                                            { 
                                                Diplomes.map(diplome => (
                                                    <li key={ diplome }>{ diplome }</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ContactFreeForm
                className="contactArea"
            />

            <Footer />
        </Fragment>
    )
}
// --------------------------------------------------


// -------------------------------------------------- EXPORTS
export default AurelieNosal
// --------------------------------------------------