// -------------------------------------------------- IMPORTS
import React, { Fragment } from 'react'
import HeaderBottom from '../../../../components/Common/HeaderBottom'
import HeaderTop from '../../../../components/Common/HeaderTop'
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import FooterArea from '../../../../components/Common/Footer'

import './style.scss'

// Images
import HeaderPicture from '../../../../images/Breadcrumb/AvocatViryChatillon.jpg'
// --------------------------------------------------


// -------------------------------------------------- DATAS
const BreadcrumbMenu = 
[
    { name: 'Accueil', route: '/' },
    { name: 'FAQ', route: '/faq' },
    { name: 'Questions relatives au recouvrement de créances' }
]
// -------------------------------------------------- 


// -------------------------------------------------- COMPONENTS
const FAQRecouvrementCreances = () => 
{
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            
            <Breadcrumb 
                className="breadcrumbArea"
                title="Questions relatives au recouvrement de créances"
                breadcrumbMenu={ BreadcrumbMenu }
                background={ HeaderPicture }
            />

            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="singleContentWrap">
                            <h1>Qu’est-ce qu’une créance ?</h1><br/>
                            <p>Une créance est un droit que détient le créancier à l’égard d’un ou plusieurs débiteurs. Il peut s’agir d’une somme d’argent.<br/>
                            Cependant, pour procéder au recouvrement de cette créance, encore faut-il que celle-ci soit certaine, liquide et exigible.<br/><br/>
                            Une créance est dite liquide lorsque son montant est chiffré ou bien lorsqu’un certain nombre d’éléments permettent son évaluation précise.<br/><br/>
                            Elle est dite exigible lorsque le créancier est en droit de contraindre son débiteur à s’exécuter. Par exemple, s’agissant d’une facture, la créance devient exigible lorsque le délai imparti pour procéder à son règlement est dépassé.</p><br/><br/>
                            
                            <h1>Comment recouvrer une dette / créance ?</h1><br/>
                            <p>Avant tout, il sera nécessaire d’adresser une mise en demeure en recommandé avec accusé de réception à votre débiteur pour réclamer son paiement et précisant le délai que ce dernier a pour s’exécuter (généralement entre 8 et 15 jours).<br/><br/>
                            A défaut de réponse postérieurement au délai fixé ou en cas de refus, vous pourrez engager une procédure judiciaire afin de procéder au recouvrement de votre créance.<br/><br/>
                            Néanmoins, si votre créance est inférieure à 5 000 euros et dans un certain nombre d’autres cas, vous devrez, avant toute saisine d’une juridiction, mettre en place une conciliation judiciaire, une médiation, ou bien une procédure participative.<br/><br/>
                            Si la mesure choisie est un échec, vous retrouverez alors toute latitude pour entamer des poursuites à l’encontre de votre débiteur et obtenir un jugement le condamnant (titre exécutoire).</p><br/><br/>

                            <h1>Je dispose d’une décision mais le débiteur refuse toujours de payer, comment faire ?</h1><br/><br/>
                            <p>Première chose : cette décision a-t-elle été signifiée à votre débiteur par un huissier de justice ?<br/><br/>
                            En effet, la signification par huissier est primordiale pour procéder à l’exécution forcée de la décision obtenue à votre profit.<br/><br/>
                            Si tel est bien le cas, vous pourrez alors mettre à exécution le jugement ou l’ordonnance rendu pour obliger le débiteur à s’exécuter.<br/><br/>
                            Plusieurs choix s’offrent alors à vous : vous pouvez mettre en place une saisie des rémunérations, à conditions que le débiteur soit salarié, procéder à la saisie de ses biens aux fins de vente, saisir les sommes qui vous sont dues sur son compte bancaire, à condition que celui-ci soit créditeur.</p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterArea />
        </Fragment>
    )
}
// -------------------------------------------------- 


// -------------------------------------------------- EXPORTS
export default FAQRecouvrementCreances
// --------------------------------------------------